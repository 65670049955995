const REFRESH_MICROFRONTEND = 'REFRESH_MICROFRONTEND';

export function microFrontendDefaultState() {
  return {
    containerRefreshKeys: {
      CompanyMicroFrontend: null,
      PhysicalLocationMicroFrontend: null
    }
  };
}

export function refreshMicroFrontend(dispatch, containerName) {
  dispatch({
    type: REFRESH_MICROFRONTEND,
    payload: {
      containerName,
      refreshKey: Date.now()
    }
  });
}

export default (state = microFrontendDefaultState(), action) => {
  const { payload } = action;
  const { containerRefreshKeys } = state;

  switch (action.type) {
    case REFRESH_MICROFRONTEND:
      containerRefreshKeys[payload.containerName] = payload.refreshKey;

      return {
        ...state,
        containerRefreshKeys
      };

    default:
      return state;
  }
};
