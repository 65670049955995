import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';

export default function ManagerControlChecklistApprovalsSettingComponent({ currentLanguage }) {
  return (
    <Can I="view" a="ManagerControlChecklistApprovalsSetting">
      <LoadingIframe src={`${Config.reactApp.MANAGER_CONTROL_CHECKLIST_APPROVALS_SETTING_URL}?iframe_language=${currentLanguage}`} />
    </Can>
  );
}

ManagerControlChecklistApprovalsSettingComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired
};
